import { CalendarSelections } from '@wix/bookings-catalog-calendar-viewer-utils';
import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { getEndOfDayFromLocalDateTime } from '../../../../utilsDeprecated/dateAndTime/dateAndTime';
import { CalendarStateDeprecated } from '../../controller';
import { FilterTypes } from '../../ViewModelDeprecated/filterViewModel/filterViewModel';
import { createSlotVeloAPIFactory } from '@wix/widget-plugins-ooi/velo';
import {
  AVAILABILITY_PLUGIN_SLOT_ID,
  STAFF_RESOURCE_ID,
} from '../../../../constants/constants';
import { AvailabilityPreferences } from '../../../../types/types';
import {
  mapServiceLocationToV2,
  mapServiceTypeToV2,
} from '../../../../utilsDeprecated/service/mapServiceToV2';

export type UpdateAvailabilityPlugin = () => void;

export const createUpdateAvailabilityPlugin = ({
  getControllerState,
  context,
  controllerConfig,
}: ActionFactoryParams<
  CalendarStateDeprecated,
  CalendarContext
>): UpdateAvailabilityPlugin => {
  return () => {
    const [state] = getControllerState();
    const { selectedDate, selectedRange, selectedTimezone } = state;
    const { calendarSelections } = context;

    const timezone = selectedTimezone!;
    const fromLocaleDate = selectedDate || selectedRange?.from!;
    const toLocaleDate = selectedDate
      ? getEndOfDayFromLocalDateTime(selectedDate)
      : selectedRange?.to!;
    const locations = getLocations({ state, calendarSelections });
    const services = getServices({ state, calendarSelections });

    const availabilityPreferences: AvailabilityPreferences = {
      fromLocaleDate,
      toLocaleDate,
      timezone,
      locations,
      services,
    };

    const slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
    const availabilityPluginSlotApi = slotAPIFactory.getSlotAPI(
      AVAILABILITY_PLUGIN_SLOT_ID,
    );

    availabilityPluginSlotApi.availabilityPreferences = availabilityPreferences;
  };
};

const getServices = ({
  state,
  calendarSelections,
}: {
  state: CalendarStateDeprecated;
  calendarSelections?: CalendarSelections;
}): AvailabilityPreferences['services'] => {
  const { servicesInView, filterOptions } = state;

  if (calendarSelections) {
    return calendarSelections.services.map(
      ({ id: serviceId, resources: resourceIds }) => ({
        serviceType: mapServiceTypeToV2(
          servicesInView.find((s) => s.id === serviceId)?.info.type!,
        )!,
        serviceId,
        resourceTypes: [
          {
            resourceTypeId: STAFF_RESOURCE_ID,
            resourceIds,
          },
        ],
      }),
    );
  }

  const resourceIds = filterOptions[FilterTypes.STAFF_MEMBER];

  return servicesInView.map((service) => ({
    serviceId: service.id,
    serviceType: mapServiceTypeToV2(service.info.type)!,
    resourceTypes: [
      {
        resourceTypeId: STAFF_RESOURCE_ID,
        resourceIds,
      },
    ],
  }));
};

const getLocations = ({
  state,
  calendarSelections,
}: {
  state: CalendarStateDeprecated;
  calendarSelections?: CalendarSelections;
}): AvailabilityPreferences['locations'] => {
  const { servicesInView, filterOptions } = state;
  const firstService = servicesInView[0];
  const locationType = mapServiceLocationToV2(firstService.locations[0]).type!;

  if (calendarSelections) {
    return [
      {
        id: calendarSelections.location,
        locationType,
      },
    ];
  }

  return filterOptions[FilterTypes.LOCATION].map((location) => ({
    id: location,
    locationType,
  }));
};
