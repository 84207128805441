import { ViewModelFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { FlowElements } from '../../Hooks/useFlow';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createEmptyStateViewModel,
  EmptyStateViewModel,
  memoizedEmptyStateViewModel,
} from '../emptyStateViewModel/emptyStateViewModel';
import { CalendarErrors, Preset } from '../../../../types/types';
import { Plan } from '@wix/ambassador-checkout-server/types';
import { getFurthestValidUntilPlan } from '../../../../utilsDeprecated/pricingPlans/pricingPlans';
import {
  BackButtonViewModel,
  createBackButtonViewModel,
  memoizedBackButtonViewModel,
} from '../backButtonViewModel/backButtonViewModel';

export enum CalendarStatus {
  INITIALIZING = 'INITIALIZING',
  IDLE = 'IDLE',
  NAVIGATING = 'NAVIGATING',
}

export type WidgetViewModel = {
  status: CalendarStatus;
  errorNotificationText: string;
  focusedElement?: FlowElements;
  emptyStateViewModel?: EmptyStateViewModel;
  preset: Preset;
  backButtonViewModel?: BackButtonViewModel;
};

export const memoizedWidgetViewModel: MemoizedViewModalFactory<WidgetViewModel> =
  {
    dependencies: {
      state: ['calendarErrors', 'focusedElement', 'calendarStatus'],
      subDependencies: [
        memoizedEmptyStateViewModel.dependencies,
        memoizedBackButtonViewModel.dependencies,
      ],
    },
    createViewModel: createWidgetViewModel,
  };

export function createWidgetViewModel({
  state,
  context,
}: ViewModelFactoryParams<CalendarStateDeprecated, CalendarContext>): WidgetViewModel {
  const {
    calendarErrors,
    focusedElement,
    calendarStatus,
    initialErrors,
    purchasedPricingPlans,
  } = state;
  const { t, preset } = context;

  let emptyStateViewModel;
  if (initialErrors.length > 0) {
    emptyStateViewModel = createEmptyStateViewModel({ state, context });
  }

  let errorNotificationText = '';
  errorNotificationText = getErrorNotificationText(
    calendarErrors,
    t,
    purchasedPricingPlans,
  );

  const backButtonViewModel = createBackButtonViewModel({ state, context });

  return {
    status: calendarStatus,
    errorNotificationText,
    focusedElement,
    emptyStateViewModel,
    preset,
    backButtonViewModel,
  };
}

const getErrorNotificationText = (
  calendarErrors: CalendarErrors[],
  t: TFunction,
  purchasedPricingPlans?: Plan[],
) => {
  if (calendarErrors.includes(CalendarErrors.NO_TIME_SELECTED_ERROR)) {
    return t('app.notification.no-time-selected-error.text');
  }

  if (
    calendarErrors.includes(
      CalendarErrors.NO_VALID_PRICING_PLAN_IN_RESCHEDULE_FLOW_ERROR,
    )
  ) {
    const furthestValidUntilPlan = getFurthestValidUntilPlan(
      purchasedPricingPlans,
    );
    return t('app.toast.pricing-plan-not-valid-error', {
      planName: furthestValidUntilPlan?.planName,
      planDate: new Date(furthestValidUntilPlan?.validUntil!),
    });
  }
  return '';
};
