import { ViewModelFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createDatePickerViewModel,
  memoizedDatePickerViewModel,
} from '../datePickerViewModel/datePickerViewModel';
import { memoizedTimezoneSelectionViewModel } from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import { DailyAgendaWeeklyPickerLayoutViewModel } from '../bodyViewModel/bodyViewModel.types';
import { formatLocalDateTimeToDateView } from '../../../../utilsDeprecated/dateAndTime/dateAndTime';
import {
  createNoAvailableSlotsViewModel,
  NoAvailableSlotsViewModel,
} from '../noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import {
  createAgendaSlotsViewModel,
  enrichSlotAvailability,
  memoizedAgendaSlotsViewModel,
} from '../agendaSlotsViewModel/agendaSlotsViewModel';
import { getNumberOfAvailableSlotsAnnouncement } from '../../../../utilsDeprecated/accessibility/announcements/announcements';
import { isSlotStartsOnDate } from '../../../../utilsDeprecated/slotAvailability/slotAvailability';

export const memoizedDailyAgendaWeeklyPickerLayoutViewModel: MemoizedViewModalFactory<DailyAgendaWeeklyPickerLayoutViewModel> =
  {
    dependencies: {
      settings: ['dateAndTimeSectionHeader'],
      state: ['selectedDate', 'slotsStatus', 'availableSlots'],
      subDependencies: [
        memoizedDatePickerViewModel.dependencies,
        memoizedTimezoneSelectionViewModel.dependencies,
        memoizedAgendaSlotsViewModel.dependencies,
      ],
    },
    createViewModel: createDailyAgendaWeeklyPickerLayoutViewModel,
  };

export function createDailyAgendaWeeklyPickerLayoutViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarStateDeprecated,
  CalendarContext
>): DailyAgendaWeeklyPickerLayoutViewModel {
  const { selectedDate, slotsStatus, availableSlots } = state;
  const { businessInfo, t } = context;

  const dateRegionalSettingsLocale = businessInfo!.dateRegionalSettingsLocale;
  const formattedSelectedDate =
    selectedDate &&
    formatLocalDateTimeToDateView(selectedDate, dateRegionalSettingsLocale);

  const noAvailableSlotsViewModel: NoAvailableSlotsViewModel =
    createNoAvailableSlotsViewModel({
      state,
      context,
    });

  const slotsAtSelectedDate =
    availableSlots?.availabilityEntries?.filter((slotAvailability) =>
      isSlotStartsOnDate({
        rfcStartTime: slotAvailability!.slot!.startDate!,
        dateAsLocalDate: selectedDate!,
      }),
    ) || [];
  const enrichedSlotsAtSelectedDate =
    enrichSlotAvailability(slotsAtSelectedDate);
  const slots = createAgendaSlotsViewModel({
    state,
    context,
    slots: enrichedSlotsAtSelectedDate,
  });

  const accessibility = {
    onTimePickerLoadedAnnouncement: getNumberOfAvailableSlotsAnnouncement({
      numberOfAvailableSlots: slotsAtSelectedDate.length,
      t,
    }),
  };

  return {
    datePickerViewModel: createDatePickerViewModel({ state, context }),
    timePicker: {
      status: slotsStatus,
      formattedSelectedDate,
      noAvailableSlotsViewModel,
      slots,
      accessibility,
    },
  };
}
