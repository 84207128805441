import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { Plan } from '@wix/ambassador-checkout-server/types';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { Booking } from '@wix/bookings-checkout-api';
import { Service } from '@wix/bookings-uou-types';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { bookingsCalendarErrorMessages } from '@wix/bi-logger-wixboost-ugc/v2';
import { FlowElements } from './Hooks/useFlow';
import { EmptyStateType } from './ViewModelDeprecated/emptyStateViewModel/emptyStateViewModel';
import { CalendarStatus } from './ViewModelDeprecated/widgetViewModel/widgetViewModel';
import {
  DialogState,
  DialogType,
} from './ViewModelDeprecated/dialogViewModel/dialogViewModel';
import {
  CalendarViewModel,
  createMemoizedCalendarViewModelFactory,
} from './ViewModelDeprecated/viewModel';
import {
  CalendarActions,
  createCalendarActions,
} from './ActionsDeprecated/actions';
import { CalendarApi } from '../../apiDeprecated/CalendarApi';
import {
  createControlledComponent,
  Render,
} from '../../utilsDeprecated/ControlledComponent/ControlledComponent';
import { SelectedBookingPreference } from '../../utilsDeprecated/bookingPreferences/bookingPreferences';
import { createInitialState } from '../../utilsDeprecated/state/initialStateFactory';
import {
  CalendarContext,
  createCalendarContext,
} from '../../utilsDeprecated/context/contextFactory';

import {
  CalendarErrors,
  FilterOptions,
  Preset,
  SelectedVariantOptions,
  SlotsStatus,
  TriggeredByOptions,
} from '../../types/types';
import {
  isCalendarPage,
  isCalendarWidget,
} from '../../utilsDeprecated/presets';
import type { ServicesPreferencesModalData } from '@wix/bookings-services-preferences-modal/types';
import {
  getUrlQueryParamValue,
  BookingsQueryParams,
  extractCalendarSelections,
  isPricingPlanInstalled as isPricingPlanInstalledUtils,
  isMemberAreaInstalled as isMemberAreaInstalledUtils,
  getServiceSlug,
  CALENDAR_PAGE_URL_PATH_PARAM,
  ReservedSlug,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export type CalendarState = {
  calendarStatus: CalendarStatus;
  slotsStatus: SlotsStatus;
  servicesInView: Service[];
  selectedDate?: string;
  selectedDateTrigger?: TriggeredByOptions;
  selectedTimezone?: string;
  selectedRange?: {
    from: string;
    to: string;
  };
  selectedTime?: string;
  selectableSlotsAtSelectedTime?: SlotAvailability[];
  availableSlots?: QueryAvailabilityResponse;
  availableSlotsPerDay?: QueryAvailabilityResponse;
  selectedBookingPreferences: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  rescheduleBookingDetails?: Booking;
  dialog?: {
    type: DialogType;
    state: DialogState;
  };
  filterOptions: FilterOptions;
  focusedElement?: FlowElements;
  initialErrors: EmptyStateType[];
  purchasedPricingPlans: Plan[];
  isUserLoggedIn: boolean;
  selectedVariantsOptions: SelectedVariantOptions[];
  serviceVariantsMap: Record<string, ServiceOptionsAndVariants>;
  servicesPreferencesModalData?: ServicesPreferencesModalData;
};

export const createControllerDeprecated = (
  preset: Preset,
  settingsParams: any,
  flowAPI: ControllerFlowAPI,
): IWidgetController => {
  const { controllerConfig, experiments } = flowAPI;
  const isCalendarPagePreset = isCalendarPage(preset);
  const isCalendarWidgetPreset = isCalendarWidget(preset);
  const subscriptions: CalendarContext['subscriptions'] = {};
  let rerender: Render<CalendarState> = async () => {};
  let publicData = controllerConfig.config.publicData.COMPONENT || {};
  let calendarContext: CalendarContext;
  let initialState: CalendarState;
  let servicesInView: Service[];
  let pageReady: () => Promise<void>;
  // eslint-disable-next-line prefer-const
  pageReady = async () => {
    const { reportError } = flowAPI;

    const calendarApi = new CalendarApi({
      reportError,
      flowAPI,
      settingsParams,
      preset,
    });

    const initialErrors: EmptyStateType[] = [];

    const onError = (type: EmptyStateType) => initialErrors.push(type);

    const isAnonymousCancellationFlow =
      getUrlQueryParamValue(
        flowAPI.controllerConfig.wixCodeApi,
        BookingsQueryParams.REFERRAL,
      ) === 'batel';
    const selectedService = flowAPI.settings.get(
      settingsParams.selectedService,
    );
    const shouldUseCalendarDummyViewModel =
      flowAPI.environment.isEditor && selectedService === '';
    const currentUser = controllerConfig.wixCodeApi.user.currentUser;
    const isDateAndTimeViewMode = await isDateAndTimeMode({
      flowAPI,
      preset,
    });
    const calendarSelections =
      experiments.enabled('specs.bookings.multiServiceAppointment') &&
      isDateAndTimeViewMode
        ? await extractCalendarSelections({
            session: flowAPI.controllerConfig.platformAPIs.storage.session,
          })
        : undefined;
    const [
      catalogData,
      rescheduleBookingDetails,
      isPricingPlanInstalled,
      isMemberAreaInstalled,
    ] = await Promise.all([
      calendarApi.getCatalogData({
        onError,
        calendarSelections,
        isDateAndTimeViewMode,
      }),
      calendarApi.getBookingDetails({
        onError: (type: EmptyStateType) => {
          if (!isAnonymousCancellationFlow) {
            onError(type);
          }
        },
      }),
      isPricingPlanInstalledUtils(flowAPI.controllerConfig.wixCodeApi).catch(
        () => false,
      ),
      isMemberAreaInstalledUtils({
        wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      }).catch(() => false),
    ]);

    servicesInView = catalogData?.services || [];

    const allPurchasedPricingPlans =
      (isCalendarPagePreset || isCalendarWidgetPreset) &&
      (!calendarSelections || calendarSelections.services.length === 1)
        ? await calendarApi.getPurchasedPricingPlans({
            currentUser,
            service: servicesInView.length ? servicesInView[0] : undefined,
          })
        : [];

    initialState = createInitialState({
      wixCodeApi: controllerConfig.wixCodeApi,
      servicesInView,
      staffMembers: catalogData?.staffMembers,
      rescheduleBookingDetails,
      initialErrors,
      allPurchasedPricingPlans,
      isPricingPlanInstalled,
      isUserLoggedIn: currentUser.loggedIn,
      servicesVariants: catalogData?.servicesVariants,
      experiments,
      calendarSelections,
    });
    calendarContext = await createCalendarContext({
      flowAPI,
      businessInfo: catalogData?.businessInfo,
      activeFeatures: catalogData?.activeFeatures,
      calendarApi,
      initialState,
      isPricingPlanInstalled,
      isMemberAreaInstalled,
      settingsParams,
      preset,
      calendarSelections,
      reloadWidget: pageReady,
      isDateAndTimeViewMode,
      subscriptions,
    });

    const { onStateChange, render, controllerActions, setState } =
      await createControlledComponent<
        CalendarState,
        CalendarActions,
        CalendarViewModel,
        CalendarContext
      >({
        controllerConfig,
        initialState,
        viewModelFactory: createMemoizedCalendarViewModelFactory(
          shouldUseCalendarDummyViewModel,
        ),
        actionsFactory: createCalendarActions,
        context: calendarContext,
      });
    rerender = render;
    if (
      isAnonymousCancellationFlow &&
      !controllerConfig.wixCodeApi.user.currentUser.loggedIn &&
      !flowAPI.environment.isSSR &&
      getUrlQueryParamValue(
        flowAPI.controllerConfig.wixCodeApi,
        BookingsQueryParams.BOOKING_ID,
      )
    ) {
      setTimeout(async () => {
        try {
          // @ts-expect-error
          await controllerConfig.wixCodeApi.user.promptLogin();
        } catch (e) {
          //
        }

        const updatedRescheduleBookingDetails =
          await calendarApi.getBookingDetails({
            onError,
          });
        setState({
          rescheduleBookingDetails: updatedRescheduleBookingDetails,
        });
      }, 10);
    }

    if (isCalendarPagePreset) {
      const seoData = catalogData?.seoData?.length
        ? catalogData.seoData[0]
        : undefined;

      await flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.BOOKINGS_CALENDAR,
        itemData: seoData as any,
      });
    }

    const { biLogger } = calendarContext;

    if (!flowAPI.environment.isSSR) {
      onStateChange((state) => {
        biLogger.update(state, calendarContext);
      });
    }

    if (isCalendarPagePreset || isCalendarWidgetPreset) {
      flowAPI.controllerConfig.wixCodeApi.user.onLogin(
        controllerActions.onUserLoggedIn,
      );
    }

    const serviceNotFound = initialState.initialErrors.includes(
      EmptyStateType.SERVICE_NOT_FOUND,
    );

    if (serviceNotFound) {
      void biLogger.report(
        bookingsCalendarErrorMessages({
          errorMessage: 'SERVICE_NOT_FOUND',
        }),
      );
    }
  };

  if (isCalendarPagePreset) {
    controllerConfig.wixCodeApi.location.onChange(() => pageReady());
  }

  return {
    pageReady,
    exports() {
      return {
        onNextClicked(
          overrideCallback: CalendarContext['subscriptions']['onNextClicked'],
        ) {
          subscriptions.onNextClicked = overrideCallback;
        },
      };
    },
    async updateConfig($w, newConfig) {
      const updatedPublicData = newConfig.publicData.COMPONENT || {};
      const isCalendarLayoutChanged =
        publicData.calendarLayout !== updatedPublicData.calendarLayout;
      const isLocationsSelectionChanged =
        publicData.selectedLocations !== updatedPublicData.selectedLocations;
      const isCategoriesSelectionChanged =
        publicData.selectedCategories !== updatedPublicData.selectedCategories;
      const isServiceSelectedChanged =
        publicData.selectedService !== updatedPublicData.selectedService;
      const isSlotsAvailabilityChanged =
        publicData.slotsAvailability !== updatedPublicData.slotsAvailability;
      publicData = updatedPublicData;

      if (
        !isCalendarPagePreset &&
        (isLocationsSelectionChanged ||
          isCategoriesSelectionChanged ||
          isServiceSelectedChanged ||
          isSlotsAvailabilityChanged)
      ) {
        pageReady();
      } else {
        await rerender({
          resetState: isCalendarLayoutChanged,
        });
      }
    },
  };
};

const isDateAndTimeMode = async ({
  flowAPI,
  preset,
}: {
  flowAPI: ControllerFlowAPI;
  preset: Preset;
}) => {
  if (
    !flowAPI.experiments.enabled('specs.bookings.multiServiceAppointment') ||
    !isCalendarPage(preset)
  ) {
    return false;
  }

  const serviceSlug = await getServiceSlug({
    wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
    pageName: CALENDAR_PAGE_URL_PATH_PARAM,
  });

  return serviceSlug === ReservedSlug.DATE_AND_TIME;
};
