import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { WidgetElements } from '../../../../utilsDeprecated/bi/consts';
import { CalendarStateDeprecated } from '../../controller';
import { CloseDialogAction } from '../closeDialog/closeDialog';
import { mapDialogTypeToWidgetComponent } from '../../../../utilsDeprecated/bi/mappers';
import { bookingsCalendarClick } from '@wix/bi-logger-wixboost-ugc/v2';

export type DialogCloseElement =
  | WidgetElements.DIALOG_X_BUTTON
  | WidgetElements.DIALOG_CLOSE_BUTTON;

export type OnDialogClose = (clickedElement?: DialogCloseElement) => void;

export function createOnDialogCloseAction(
  {
    getControllerState,
    context: { biLogger, experiments },
  }: ActionFactoryParams<CalendarStateDeprecated, CalendarContext>,
  closeDialog: CloseDialogAction,
): OnDialogClose {
  return (clickedElement = WidgetElements.DIALOG_X_BUTTON) => {
    const [state] = getControllerState();
    const { dialog } = state;

    void biLogger.report(
      bookingsCalendarClick({
        component: mapDialogTypeToWidgetComponent(dialog?.type),
        element: clickedElement,
      }),
    );

    closeDialog();
  };
}
