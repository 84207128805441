import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { getBookingPreferencesForSelectedTime } from '../../../../utilsDeprecated/bookingPreferences/bookingPreferencesForSelectedTime';
import { SetFocusedElement } from '../setFocusedElement/setFocusedElement';
import { FlowElements } from '../../Hooks/useFlow';
import { isWaitingListFlow } from '../../../../utilsDeprecated/waitingList/waitingList';
import { AddError } from '../addError/addError';
import { getSelectedSlots } from '../../../../utilsDeprecated/selectedSlots/selectedSlots';
import { CalendarErrors } from '../../../../types/types';
import { bookingsPaymentMethodSelectionNextClicked } from '@wix/bi-logger-wixboost-ugc/v2';
import { NavigateToNextPage } from '../navigateToNextPage/navigateToNextPage';
import { mapFlowTypeToBiProperties } from '../../../../utilsDeprecated/bi/mappers';
import { updateCalendarStateWithPreferencesErrors } from './preferencesErrors';
import { PreferencesErrors } from '../../../../utilsDeprecated/bookingPreferences/bookingPreferences';
import { isAllSlotsAreLocked } from '../../../../utilsDeprecated/slotAvailability/slotAvailability';

export type OnNext = () => void;

export function createOnNextAction(
  actionFactoryParams: ActionFactoryParams<CalendarStateDeprecated, CalendarContext>,
  addError: AddError,
  setFocusedElement: SetFocusedElement,
  navigateToNextPage: NavigateToNextPage,
): OnNext {
  return async () => {
    const { getControllerState, context } = actionFactoryParams;
    const [state] = getControllerState();
    const { t, businessInfo, biLogger, isDateAndTimeViewMode } = context;
    const {
      selectedTime,
      selectableSlotsAtSelectedTime,
      selectedBookingPreferences,
    } = state;

    if (!selectedTime) {
      const calendarError = CalendarErrors.NO_TIME_SELECTED_ERROR;

      void biLogger.report(
        bookingsPaymentMethodSelectionNextClicked({
          userMessage: calendarError,
        }),
      );
      addError(calendarError);
      setFocusedElement(FlowElements.BOTTOM_SECTION);
      return;
    }

    if (selectableSlotsAtSelectedTime) {
      let { calendarErrors } = state;

      const bookingPreferences = getBookingPreferencesForSelectedTime({
        selectableSlotsAtSelectedTime,
        calendarErrors,
        selectedBookingPreferences,
        context,
        state,
      });

      if (!isDateAndTimeViewMode) {
        updateCalendarStateWithPreferencesErrors(
          bookingPreferences,
          addError,
          selectedBookingPreferences,
        );
      }

      [{ calendarErrors }] = getControllerState();

      const isCalendarErrorsHasPreferencesErrors = calendarErrors.some(
        (error) => PreferencesErrors.includes(error),
      );

      const selectedSlots = getSelectedSlots({
        selectableSlotsAtSelectedTime,
        dateRegionalSettingsLocale: businessInfo!.dateRegionalSettingsLocale!,
        t,
        selectedBookingPreferences,
      });

      const isWaitingList = isWaitingListFlow({
        selectableSlots: selectableSlotsAtSelectedTime,
        selectedBookingPreferences,
        bookingPreferences,
      });

      if (!isCalendarErrorsHasPreferencesErrors && selectedSlots.length > 0) {
        await navigateToNextPage({
          selectedSlots,
          isWaitingListFlow:
            isWaitingList || isAllSlotsAreLocked(selectedSlots),
        });
      } else {
        void biLogger.report(
          bookingsPaymentMethodSelectionNextClicked({
            selectedSlot: selectedTime,
            ...mapFlowTypeToBiProperties({ isWaitingList }),
          }),
        );
      }
    }
  };
}
