import { TimeSlotsNotificationType } from '../../ViewModelDeprecated/timeSlotsNotificationViewModel/timeSlotsNotificationViewModel';
import { GoToNextAvailableDate } from '../goToNextAvailableDate/goToNextAvailableDate';

export type OnSlotsNotificationCtaClick = (
  notificationType: TimeSlotsNotificationType,
) => void;

export function createOnSlotsNotificationCtaClick(
  goToNextAvailableDate: GoToNextAvailableDate,
): OnSlotsNotificationCtaClick {
  return (notificationType: TimeSlotsNotificationType) => {
    if (notificationType === TimeSlotsNotificationType.ALL_SESSIONS_ARE_FULL) {
      return goToNextAvailableDate();
    }
  };
}
