import { bookingsCalendarClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { WidgetComponents, WidgetElements } from '../../../../utilsDeprecated/bi/consts';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import {
  getUrlQueryParamValue,
  BookingsQueryParams,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export type OnServiceChange = (serviceId: string, serviceSlug: string) => void;

export const createOnServiceChange =
  ({
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarStateDeprecated, CalendarContext>): OnServiceChange =>
  (serviceId, serviceSlug) => {
    const [state] = getControllerState();
    const { selectedTimezone } = state;
    const {
      biLogger,
      flowAPI: {
        controllerConfig: { wixCodeApi, platformAPIs },
      },
    } = context;

    const referral = getUrlQueryParamValue(
      wixCodeApi,
      BookingsQueryParams.REFERRAL,
    );

    void biLogger.report(
      bookingsCalendarClick({
        component: WidgetComponents.CHANGE_SERVICE,
        element: WidgetElements.MENU_OPTION,
        serviceId,
      }),
    );

    navigateToBookingsCalendarPage(wixCodeApi, platformAPIs, {
      serviceSlugOrBasket: serviceSlug,
      navigationContext: {
        timezone: selectedTimezone,
        referral,
      },
    });
  };
