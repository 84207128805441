import { Service, ServiceType } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { isServiceVariantWithStaff } from '../dynamicPricing/dynamicPricing';
import { CalendarStateDeprecated } from '../../components/BookingCalendar/controller';
import { FilterTypes } from '../../components/BookingCalendar/ViewModelDeprecated/filterViewModel/filterViewModel';

export const isCalendarFlow = (service: Service) =>
  service?.info.type !== ServiceType.COURSE &&
  service?.policy.isBookOnlineAllowed;

export function isBookingsEnabled({
  servicesTypes,
  activeFeatures,
  isTemplateMode,
}: {
  servicesTypes: ServiceType[];
  activeFeatures: GetActiveFeaturesResponse;
  isTemplateMode: boolean;
}): boolean {
  if (isTemplateMode) {
    return true;
  }

  return servicesTypes.every((serviceType) => {
    switch (serviceType) {
      case ServiceType.GROUP:
        return !!activeFeatures.applicableForGroups;
      case ServiceType.INDIVIDUAL:
        return !!activeFeatures.applicableForIndividual;
      default:
        return true;
    }
  });
}

export const isPendingApprovalFlow = (services: Service[]) =>
  services.some((service) => !!service.policy?.isPendingApprovalFlow);

export const canAutoAssignStaff = ({
  serviceVariantsMap,
  servicesInView,
  selectableSlotsAtSelectedTime = [],
  filterOptions,
}: Pick<
  CalendarStateDeprecated,
  | 'selectableSlotsAtSelectedTime'
  | 'servicesInView'
  | 'serviceVariantsMap'
  | 'filterOptions'
>) => {
  const resources = new Set(
    selectableSlotsAtSelectedTime.map((slot) => slot.slot?.resource?.id),
  );
  return (
    !filterOptions[FilterTypes.STAFF_MEMBER].length &&
    resources.size > 1 &&
    servicesInView.every(
      (service) =>
        !isServiceVariantWithStaff(serviceVariantsMap[service.id!]) &&
        service.policy?.isAutoAssignResourceAllowed,
    )
  );
};
